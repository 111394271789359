define("discourse/plugins/discourse-teambuild/discourse/adapters/teambuild-progress", ["exports", "discourse/adapters/rest"], function (_exports, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _rest.default.extend({
    jsonMode: true,
    pathFor(store, type, username) {
      return `/team-build/progress/${username}.json`;
    }
  });
});